<template>
    <div>
      Completed Project
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
});
</script>
<style lang="scss" scoped>
</style>
